import { RssListTeaserData, isArticleTeaserData } from '@hubcms/domain-teaser';
import { GDefaultTeaser, GList, TGridProps, createLogo } from '@hubcms/domain-teaser-grid';

export function createRssListGridItem({
  teaserData,
  storyblockTitle,
}: {
  teaserData: RssListTeaserData;
  storyblockTitle: string;
}): GList {
  const { title, items, logo } = teaserData;
  return {
    type: 'list',
    data: {
      title: logo?.url
        ? createLogo({
            url: logo.url,
            alt: logo.alt || logo.url.split('/').pop(),
            text: title,
          })
        : null,
      layout: 'none',
      isOrdered: false,
      verticalAlign: 'top',
      items: items.filter(isArticleTeaserData).map((teaserData, idx): GDefaultTeaser => {
        return {
          type: 'default-teaser',
          data: {
            teaserData: {
              ...teaserData,
              isExternal: true,
              canHideExternalIcon: true,
            },
            hasDateTime: false,
            headerLevel: 5,
            orientation: 'horizontal',
            size: 'sm',
            trackingData: {
              ...teaserData.trackingData,
              clickitemblock: storyblockTitle,
              clickitemposition: idx + 1,
            },
          },
        };
      }),
    },
  };
}
