import { ArticleTeaserData } from './article-teaser-data';
import { HtmlTeaserData } from './html-teaser-data';
import { PlaceholderTeaserData } from './placeholder-teaser-data';
import { RssListTeaserData } from './rss-list-teaser-data';

export type TeaserData = ArticleTeaserData | PlaceholderTeaserData | HtmlTeaserData | RssListTeaserData;

export function isArticleTeaserData(teaserData?: TeaserData | null): teaserData is ArticleTeaserData {
  return !!teaserData && teaserData.teaserType === 'article';
}

export function isHtmlTeaserData(teaserData?: TeaserData | null): teaserData is HtmlTeaserData {
  return !!teaserData && teaserData.teaserType === 'html';
}

export function isPlaceholderTeaserData(teaserData?: TeaserData | null): teaserData is PlaceholderTeaserData {
  return !!teaserData && teaserData.teaserType === 'placeholder';
}

export function isRssListTeaserData(teaserData?: TeaserData | null): teaserData is RssListTeaserData {
  return !!teaserData && teaserData.teaserType === 'rss-list';
}
