import {
  type ArticleTeaserData,
  type HtmlTeaserData,
  isHtmlTeaserData,
  isPlaceholderTeaserData,
  type PlaceholderTeaserData,
} from '@hubcms/domain-teaser';
import { sponsoredContentTeaserIsHighlighted } from '@hubcms/brand';

import { createHtmlGridItem } from '../../createHtmlGridItem';

import { createDefaultTeaserData } from './createDefaultTeaserData';
import type { SubsequentAreaOptions } from './types';

type TeaserOptions = {
  isAreaOnePositionTop: boolean;
  isLastInArea?: boolean;
  isOnlyItem?: boolean;
};

export function createCustomizableStoryblockTeaser(
  teaserData: HtmlTeaserData | ArticleTeaserData | PlaceholderTeaserData,
  areaOptions: SubsequentAreaOptions,
  { isAreaOnePositionTop, isLastInArea = false, isOnlyItem = false }: TeaserOptions,
) {
  const isHtmlTeaser = isHtmlTeaserData(teaserData);
  if (isHtmlTeaser) {
    const htmlTeaser = createHtmlGridItem(teaserData);
    if (isOnlyItem) {
      htmlTeaser.gridProps = {
        ...htmlTeaser.gridProps,
        rowLg: 'span 2',
      };
    }
    return htmlTeaser;
  }
  const isPlaceholder = isPlaceholderTeaserData(teaserData);
  const hasSponsoredThemeOnTeaserLevel = !isPlaceholder && teaserData.theme === 'sponsored-content';
  const isTeaserHighlighted =
    areaOptions.isHighlighted || (hasSponsoredThemeOnTeaserLevel && sponsoredContentTeaserIsHighlighted);

  return createDefaultTeaserData(teaserData, {
    ...areaOptions,
    isTeaserHighlighted,
    isAreaOnePositionTop,
    isLastInArea,
  });
}
