import {
  AdditionalTeaserContent,
  HtmlTeaserFragment,
  RealTeaserContent,
  TeaserFragment,
  TeaserOrHtmlBlock,
  isAdditionalTeaserContent,
  isHtmlTeaserFragment,
  isRealTeaserContent,
  isRssArticleListFragment,
} from '@hubcms/domain-cook';

export type SingleTeaserFragment = TeaserFragment<RealTeaserContent | AdditionalTeaserContent> | HtmlTeaserFragment;

export function isSingleTeaserFragment(teaserFragment: TeaserOrHtmlBlock): teaserFragment is SingleTeaserFragment {
  if (!teaserFragment) {
    return false;
  }
  return (
    isHtmlTeaserFragment(teaserFragment) ||
    isRssArticleListFragment(teaserFragment) ||
    isRealTeaserContent(teaserFragment.content) ||
    isAdditionalTeaserContent(teaserFragment.content)
  );
}
