import {
  createTeaserFragment,
  isArticleTeaserContent,
  isB2bContent,
  isExternalLinkTeaserContent,
  isHtmlTeaserFragment,
  isRealTeaserContent,
  isRssArticleListFragment,
  isTagIntroB2BTeaserContent,
  isVisualStoryTeaserContent,
  RssListTeaserFragment,
  TeaserOrHtmlBlock,
} from '@hubcms/domain-cook';
import {
  ArticleTeaserData,
  createArticleTeaserData,
  createHtmlTeaserData,
  createRssTeaserListData,
  HtmlTeaserData,
  RssListTeaserData,
  TeaserPropsOptions,
} from '@hubcms/domain-teaser';
import { isSameDay } from 'date-fns';
import { getArticleCounts } from '@hubcms/utils-tracking';

import { isSingleTeaserFragment } from '../domain/single-teaser-fragment';
import { getTeaserImage } from '../utils/getTeaserImage';

import { getTeaserTitle } from '../utils/getTeaserTitle';
import { getTeaserIntroduction } from '../utils/getTeaserIntroduction';
import { getTeaserHeroVideo } from '../utils/getTeaserHeroVideo';
import { mapAuthors } from '../utils/mapAuthors';
import { appendDefaultArticleTrackingData } from './appendDefaultArticleTrackingData';

export function mapTeaserData(
  article: TeaserOrHtmlBlock,
  options: TeaserPropsOptions,
): ArticleTeaserData | HtmlTeaserData | RssListTeaserData | null {
  if (!isSingleTeaserFragment(article)) {
    return null;
  }

  if (isHtmlTeaserFragment(article)) {
    return createHtmlTeaserData({
      id: article.content.id,
      html: article.content.htmlBlock,
    });
  }

  if (isRssArticleListFragment(article)) {
    const rssArticleListContent = article.content as RssListTeaserFragment['content'];

    // don't render if the rss feed fetch did not return content
    if (!rssArticleListContent.content) {
      return null;
    }

    return createRssTeaserListData({
      id: rssArticleListContent.id,
      title: rssArticleListContent.title,
      logo: {
        url: rssArticleListContent.logo[0].content.fields.baseWidth.href_full,
        alt:
          rssArticleListContent.logo[0].content.fields.title ??
          rssArticleListContent.logo[0].content.fields.caption ??
          rssArticleListContent.logo[0].content.fields.baseWidth.href_full.split('/').pop() ??
          '',
      },
      items: (rssArticleListContent.content || []).map(rssArticle =>
        createArticleTeaserData({
          contentType: 'rssArticle',
          url: rssArticle.href,
          image: rssArticle.teaserImage,
          trackingData: {
            // eslint-disable-next-line camelcase
            utm_source: rssArticleListContent.fields?.source,
            // eslint-disable-next-line camelcase
            utm_content: rssArticleListContent.fields?.content,
            // eslint-disable-next-line camelcase
            utm_term: rssArticleListContent.fields?.term,
            // eslint-disable-next-line camelcase
            utm_medium: rssArticleListContent.fields?.medium,
            // eslint-disable-next-line camelcase
            utm_campaign: rssArticleListContent.fields?.campaign,
          },
          ...rssArticle,
        }),
      ),
    });
  }

  const {
    todaysDate,
    showEditorialTeaserLabel,
    showEditorialTeaserSublabel,
    showSponsoredTeaserLabel,
    showSponsoredTeaserSublabel,
  } = options;

  const articleTeaserData = createArticleTeaserData({
    authors: mapAuthors(article.content),
    contentType: !isExternalLinkTeaserContent(article.content) ? article.content.type : '',
    id: article.content.id,
    image: getTeaserImage(article),
    introduction: getTeaserIntroduction(article),
    list: options.list,
    title: getTeaserTitle(article),
    trackingData: article.trackingData,
    url: article.content.href,
  });

  if (isExternalLinkTeaserContent(article.content) && article.content.fields?.link) {
    articleTeaserData.url = article.content.fields.link;
    articleTeaserData.isExternal = true;
  }

  if (isB2bContent(article.content)) {
    articleTeaserData.theme = 'sponsored-content';
  }

  if (isRealTeaserContent(article.content)) {
    const updatedDate = article.content.updated;
    const publishedDate = article.content.published;
    articleTeaserData.dateTime = updatedDate ?? publishedDate ?? '';
    articleTeaserData.isDateToday = isSameDay(new Date(todaysDate), new Date(articleTeaserData.dateTime));
    articleTeaserData.isPremium = article.content.fields?.isPremium;
  }

  if (article.content.__typename === 'Video' || article.content.__typename === 'Podcast') {
    articleTeaserData.duration = article.content.fields.duration ?? '';
  }
  const heroMediaVideo = getTeaserHeroVideo(article);

  if (heroMediaVideo) {
    articleTeaserData.hasHeroVideo = true;
    articleTeaserData.duration = heroMediaVideo.duration;
  }

  let labelDropdown: string | null = null;
  if (isArticleTeaserContent(article.content) || isTagIntroB2BTeaserContent(article.content)) {
    labelDropdown = article.content.fields?.labelDropdown;
  }
  if (labelDropdown === 'none') {
    labelDropdown = null;
  }
  const labelOverride = article.content.fields?.labelOverride;
  const showTeaserLabel = articleTeaserData.theme === 'sponsored-content' ? showSponsoredTeaserLabel : showEditorialTeaserLabel;
  const showTeaserSublabel =
    articleTeaserData.theme === 'sponsored-content' ? showSponsoredTeaserSublabel : showEditorialTeaserSublabel;

  if (showTeaserLabel) {
    articleTeaserData.label = labelOverride || labelDropdown || '';
  }
  if (showTeaserSublabel) {
    articleTeaserData.subLabel = article.content.fields?.sublabel || '';
  }

  if (isArticleTeaserContent(article.content) && !isVisualStoryTeaserContent(article.content)) {
    const { articleBodyCharacterCount, articleWordCount } = getArticleCounts(article.content.fields.articleCounts || null);

    articleTeaserData.trackingData = {
      ...articleTeaserData.trackingData,
      articlebodycharactercount: articleBodyCharacterCount !== null ? articleBodyCharacterCount : null,
      articlewordcount: articleWordCount !== null ? articleWordCount : null,
    };
  }

  return appendDefaultArticleTrackingData(articleTeaserData);
}
